/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import SiteMenu from "./SiteMenu/SiteMenu"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto-mono/500.css"
import "@fontsource/roboto-mono/400.css"
import "@fontsource/roboto-mono/300.css"
import "@fontsource/nunito/300.css"
import "@fontsource/nunito/400.css"
import "@fontsource/nunito/500.css"

import "./normalize.css"
import "./animate.css"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <SiteMenu />

      <div>
        <main>{children}</main>

        <footer>© {new Date().getFullYear()}</footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
