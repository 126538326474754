import React from "react"

import "./MobileMenuIcon.css"

const MobileMenuIcon = ({ parentStyles, transformToX = false }) => (
  <svg
    id="elements"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44 44"
    className={`${transformToX ? "transform" : ""} menu-icon ${parentStyles}`}
  >
    <title>Mobile Menu Button</title>
    <line className="hamburger-line-1" x1="10" y1="14.68" x2="34" y2="14.68" />
    <line className="hamburger-line-2" x1="10" y1="22" x2="34" y2="22" />
    <line className="hamburger-line-3" x1="10" y1="29.32" x2="34" y2="29.32" />
  </svg>
)

export default MobileMenuIcon
