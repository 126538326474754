import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"

import brownFlower3 from "../images/brownFlower3.svg"
import brownFlower5 from "../images/brownFlower5.svg"
import redFlower3 from "../images/redFlower1.svg"
import redFlower4 from "../images/redFlower4.svg"
import blueFlower3 from "../images/blueFlower3.svg"
import pinkFlower1 from "../images/pinkFlower1.svg"
import pinkFlower2 from "../images/pinkFlower2.svg"
import pinkFlower3 from "../images/pinkFlower3.svg"
import bang2 from "../images/bang2.svg"
import leaf1 from "../images/leaf1.svg"
import leaf2 from "../images/leaf2.svg"
import leaf3 from "../images/leaf3.svg"
import leaf4 from "../images/leaf4.svg"
import leaf5 from "../images/leaf5.svg"
import leaf6 from "../images/leaf6.svg"

import "./postindex.css"

const PostIndex = ({ data, pageContext }) => {
  const { pageContent } = data
  const { index } = pageContext

  return (
    <Layout>
      <section className={`index-page-section wrapper color${index + 1}`}>
        <div className="index-content">
          <h1>{pageContent.menuTitle}</h1>
          {pageContent.menuItems.map((menuItem) => {
            if (menuItem.__typename === "ContentfulPostPage") {
              return (
                <h2 key={menuItem.contentful_id}>
                  <Link to={`/post/${menuItem.slug}/`}>{menuItem.title}</Link>
                </h2>
              )
            }
            if (menuItem.__typename === "ContentfulSubMenu") {
              return (
                <Fragment key={menuItem.contentful_id}>
                  <h2>{menuItem.menuTitle}</h2>
                  <ul className="sub-menu-list">
                    {menuItem.allPages.map((page) => (
                      <li key={page.id}>
                        <Link to={`/post/${page.slug}/`}>{page.title}</Link>
                      </li>
                    ))}
                  </ul>
                </Fragment>
              )
            }
          })}
        </div>
        <div className="flowers">
          <img
            className="flower flower10 animated jackInTheBox  faster"
            src={leaf1}
            alt=""
          />
          <img
            className="flower flower11 animated jackInTheBox "
            src={leaf2}
            alt=""
          />
          <img
            className="flower flower12 animated jackInTheBox  delay-2s"
            src={leaf3}
            alt=""
          />
          <img
            className="flower flower13 animated jackInTheBox  delay-1s"
            src={leaf4}
            alt=""
          />
          <img
            className="flower flower14 animated jackInTheBox  delay-2s"
            src={leaf5}
            alt=""
          />
          <img
            className="flower flower15 animated jackInTheBox  delay-1s"
            src={leaf6}
            alt=""
          />
          <img
            className="flower flower16 animated rotateIn delay-2s"
            src={pinkFlower2}
            alt=""
          />
          <img
            className="flower flower17 animated fadeIn faster"
            src={pinkFlower3}
            alt=""
          />
          <img
            className="flower flower18 animated jackInTheBox delay-1s"
            src={pinkFlower1}
            alt=""
          />
          <img
            className="flower flower19 animated jackInTheBox faster"
            src={brownFlower3}
            alt=""
          />
          <img
            className="flower flower20 animated rotateIn delay-2s"
            src={brownFlower5}
            alt=""
          />
          <img
            className="flower flower21 animated rotateIn delay-1s"
            src={blueFlower3}
            alt=""
          />
          <img
            className="flower flower22 animated fadeIn faster"
            src={redFlower3}
            alt=""
          />
          <img
            className="flower flower23 animated jackInTheBox delay-1s"
            src={redFlower4}
            alt=""
          />
          <img
            className="flower flower24 animated bounceIn delay-4s"
            src={bang2}
            alt=""
          />
        </div>
      </section>
    </Layout>
  )
}

export default PostIndex

export const query = graphql`
  query ($id: String!) {
    pageContent: contentfulPrimaryMenuGroup(id: { eq: $id }) {
      menuTitle
      menuItems {
        ... on ContentfulSubMenu {
          contentful_id
          __typename
          menuTitle
          allPages {
            id
            __typename
            title
            slug
          }
        }
        ... on ContentfulPostPage {
          contentful_id
          __typename
          title
          slug
        }
      }
    }
  }
`
