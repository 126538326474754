import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import MobileMenuIcon from "../MobileMenuIcon/MobileMenuIcon"
import backArrow from "../../images/back-arrow.svg"

import "./SiteMenu.css"

const SiteMenu = () => {
  const { siteMenuQuery } = useStaticQuery(graphql`
    query {
      siteMenuQuery: contentfulSiteMenu {
        menuItems {
          ... on ContentfulHomePage {
            __typename
            title
          }
          ... on ContentfulSiteGroup {
            __typename
            groupTitle
            mainGroupItems {
              ... on ContentfulPrimaryMenuGroup {
                contentful_id
                __typename
                menuTitle
                slug
              }
              ... on ContentfulPostPage {
                contentful_id
                __typename
                title
                slug
              }
            }
          }
        }
      }
    }
  `)

  const subMenuGroup = siteMenuQuery.menuItems.find(
    (item) => item.__typename === "ContentfulSiteGroup"
  )
  const homePage = siteMenuQuery.menuItems.find(
    (item) => item.__typename === "ContentfulHomePage"
  )

  const [mouseOverSubMenu, setMouseOverSubmenu] = useState(false)
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
  const [mobileSubMenuVisible, setMobileSubMenuVisible] = useState(false)

  const handleAllMenus = () => {
    setMobileMenuVisible(!mobileMenuVisible)
    setMobileSubMenuVisible(false)
  }

  return (
    <nav className="site-nav">
      <button
        type="button"
        className="mobile-menu-button"
        onClick={() => handleAllMenus()}
      >
        <MobileMenuIcon transformToX={mobileMenuVisible} />
      </button>

      <ul
        className={`main-menu ${
          mobileMenuVisible ? "mobile-menu-visible" : ""
        }`}
      >
        <li className="menu-item">
          <Link to="/">{homePage.title}</Link>
        </li>
        <li className="menu-item">
          <button
            type="button"
            className={`resources ${
              mouseOverSubMenu ? "mouse-over-sub-menu" : ""
            }`}
            onClick={() => setMobileSubMenuVisible(!mobileSubMenuVisible)}
          >
            {subMenuGroup.groupTitle}
          </button>
          <ul
            className={`sub-menu ${
              mobileSubMenuVisible ? "mobile-sub-menu-visible" : ""
            }`}
            onMouseEnter={() => setMouseOverSubmenu(true)}
            onMouseLeave={() => setMouseOverSubmenu(false)}
          >
            <li className="mobile-item">
              <button
                type="button"
                className="mobile-back-arrow"
                onClick={() => setMobileSubMenuVisible(false)}
              >
                <img src={backArrow} alt="" />
              </button>
            </li>
            {subMenuGroup.mainGroupItems.map((item) => (
              <li key={item.contentful_id} className="sub-menu-item">
                <Link
                  to={`${
                    item.__typename === "ContentfulPostPage" ? "/post" : ""
                  }/${item.slug}/`}
                >
                  {item.menuTitle || item.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li className="menu-item">
          <Link to="/about/">About</Link>
        </li>
      </ul>
    </nav>
  )
}

export default SiteMenu
